<template>
  <div class="container-fluid round px-0 mb-5">
    <div class="page-header mb-1">
      <strong>คืนโพย</strong>
    </div>
    <div v-if="roundId" class="card">
      <RoundCardHeader />

      <div class="p-2">
        <div class="d-flex justify-content-between">
          <div>
            <button class="btn btn-sm btn-danger" :disabled="!sItemIds.length" @click="refundSelects">คืนโพยที่เลือก {{sItemIds.length}} รายการ</button>
            <button class="btn btn-sm btn-primary ml-1" @click="selectAll">เลือกทั้งหมด</button>
            <button class="btn btn-sm btn-secondary ml-1" :disabled="!sItemIds.length" @click="clearSelects">ยกเลิกที่เลือก</button>
          </div>
          <div class="d-flex justify-content-end align-items-center mb-1">
            <span class="mr-1">จำนวนโพย</span>
            <b-form-input
              style="width: 60px;"
              size="sm"
              v-model="bulkLimit"
              maxlength="2"
              :number="true"
            ></b-form-input>
            <button class="btn btn-sm btn-warning ml-1" @click="refundBulk">คืนโพย</button>
          </div>
        </div>
        <table class="table table-bordered table-info table-hover mb-0">
          <thead>
            <tr>
              <th width="40">ลำดับ</th>
              <th width="40">เลือก</th>
              <th width="100">เลขที่</th>
              <th width="180" class="pb-1">ชื่อใช้งาน
                <b-form-checkbox
                  v-model="showName"
                  :value="true"
                  :unchecked-value="false"
                  class="mt-2 font-weight-normal"
                >
                  แสดงชื่อ
                </b-form-checkbox>
              </th>
              <th width="140">อัตราจ่าย</th>
              <th width="180">เวลา</th>
              <th width="100">รายการ</th>
              <th width="100">ยอดบิล</th>
              <th width="100">ส่วนลด</th>
              <th width="100">สุทธิ</th>
              <th width="100">ถูกรางวัล</th>
              <th width="120">สถานะ</th>
              <th>หมายเหตุ</th>
              <th width="130"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="item._id" :class="[{'alert-danger': item.status==='Canceled'}, {'alert-warning': item.status==='Refund'}] ">
              <td class="text-center">{{index+1}}</td>
              <td class="text-center p-0">
                <b-form-checkbox v-model="sItemIds" :value="item._id" />
              </td>
              <td class="text-center">{{item.ticketNo}}</td>
              <td class="text-center">
                {{ item.account.mainUser.username }}
                <small v-if="showName" class="text-secondary">({{item.account.mainUser.firstName}})</small>
              </td>
              <td class="text-center">{{item.rate.rateTitle}}</td>
              <td class="text-center">
                {{item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}
              </td>
              <td class="text-center">{{summary(item, 'length')}}</td>
              <td class="text-right text-success">{{summary(item, 'amount') | amountFormat}}</td>
              <td class="text-right text-danger">{{summary(item, 'discount') | amountFormat}}</td>
              <td class="text-right text-success">{{summary(item, 'credit') | amountFormat}}</td>
              <td class="text-right text-success">{{summary(item, 'won') | amountFormat}}</td>
              <td class="text-center">
                <TicketStatus :ticket="item" />
              </td>
              <td class="text-center">{{item.remarks}}</td>
              <td class="text-center p-0">
                <button class="btn btn-outline-info btn-sm m-1" title="ดูรายการแทง" @click="viewTicket(item._id)"><i class="fas fa-list"></i></button>
                <button v-if="item.status==='Accepted'" class="btn btn-warning btn-sm m-1" title="คืนโพย" @click="refundTicket(item)"><i class="fas fa-undo fa-sm"></i></button>
                <button v-if="item.status!=='Canceled'" class="btn btn-danger btn-sm m-1" title="ยกเลิกโพย" @click="cancelTicket(item)"><i class="fas fa-times"></i></button>
              </td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <tr class="alert-warning">
              <td colspan="13" class="text-center">กรุณารอซักครู่</td>
            </tr>
          </tbody>
          <tbody v-if="!isLoading && !this.items.length">
            <tr>
              <td colspan="14" class="text-center">ไม่พบรายการโพย</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
    <div v-else class="card">
      <div class="card-body">กรุณาเลือกงวดหวย</div>
    </div>

    <ViewTicketModal :is-show="isShowTicketModal" :ticket-id="viewTicketId" @close="isShowTicketModal=false" />
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import cryptoRandomString from 'crypto-random-string'
import LottoService from '@/services/lottoService'
import RoundCardHeader from '@/views/manage-lotto/components/RoundCardHeader'
import TicketStatus from '@/views/components/TicketStatus'
import ViewTicketModal from '@/views/manage-lotto/components/ViewTicketModal'
import _ from 'lodash'

export default {
  name: 'RefundTicket',
  components: {
    RoundCardHeader,
    TicketStatus,
    ViewTicketModal
  },
  data() {
    return {
      username: '',
      ticketNo: '',
      data: null,
      isLoading: false,
      showName: false,
      isShowTicketModal: false,
      viewTicketId: null,
      bulkLimit: 10,
      sItemIds: []
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket.round.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    },
    items() {
      if(!this.data)
        return []

      return this.data.tickets
    }
  },
  watch: {
    roundId() {
      if(this.roundId) {
        this.loadTickets()
      }
    }
  },
  methods: {
    loadTickets() {

      this.isLoading = true
      LottoService.getForRefund(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: (e?.message || e?.data) || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    viewTicket(ticketId) {
      this.isShowTicketModal = true
      this.viewTicketId = ticketId
    },
    cancelTicket(ticket) {
      if(!this.data)
        return

      const uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
      console.log(ticket)
      Swal.fire({
        title: '<span class="text-danger">ยืนยันยกเลิกโพย!</span>',
        html: `<h4>สมาชิก <span class="text-primary">${ticket.account.mainUser.username}</span><br>รายการแทง <span class="text-primary">${ticket?.summary?.length}</span> รายการ<br>ยอดรวม <span class="text-primary">${ticket?.summary?.amount}</span> บาท</h4>`,
        icon: 'warning',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#dc3545',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn-danger'
        }
      })
      .then((res)=>{
        if(res.isConfirmed) {
          this.isLoading = true
          LottoService.cancelTicket(ticket._id, uniqueCode).then((response)=>{
            if(response.success) {
              this.$notify({
                type: 'success',
                title: 'ยกเลิกโพยแล้ว!'
              })
              this.loadTickets()
            }else{
              throw new Error(response?.message)
            }
          })
          .catch((e)=>{
            Swal.fire({
              title: 'ผิดพลาด!',
              text: (e?.message || e?.data) || 'ยกเลิกโพยไม่สำเร็จ',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
          .finally(()=>{
            this.isLoading = false
          })
        }
      })
    },
    refundTicket(ticket) {
      if(!this.data)
        return

      const uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
      Swal.fire({
        title: '<span class="text-warning">ยืนยันคืนโพย!</span>',
        html: `<h4>สมาชิก <span class="text-primary">${ticket.account.mainUser.username}</span><br>รายการแทง <span class="text-primary">${ticket?.summary?.length}</span> รายการ<br>ยอดรวม <span class="text-primary">${ticket?.summary?.amount}</span> บาท</h4>`,
        icon: 'warning',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#dc3545',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn-warning'
        }
      })
      .then((res)=>{
        if(res.isConfirmed) {
          this.isLoading = true
          LottoService.refundTicket(ticket._id, uniqueCode).then((response)=>{
            if(response.success) {
              this.$notify({
                type: 'success',
                title: 'ยกเลิกโพยแล้ว!'
              })
              this.loadTickets()
            }else{
              throw new Error(response?.message)
            }
          })
          .catch((e)=>{
            Swal.fire({
              title: 'ผิดพลาด!',
              text: (e?.message || e?.data) || 'คืนโพยไม่สำเร็จ',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
          .finally(()=>{
            this.isLoading = false
          })
        }
      })
    },
    refundBulk() {

      const uniqueItems = _.uniqBy(this.items, (item)=>{
        return item.account.accountId
      })

      const ticketIds = uniqueItems.slice(0, this.bulkLimit).map((item)=>{
        return item._id
      })

      this.refundTickets(ticketIds)
    },
    refundSelects() {

      const items = this.sItemIds.map((id)=>{
        return this.items.find(i=>i._id===id)
      })
      .filter(i=>i?._id)

      const uniqueItems = _.uniqBy(items, (item)=>{
        return item.account.accountId
      })

      const ticketIds = uniqueItems.map((item)=>{
        return item._id
      })

      this.refundTickets(ticketIds)
    },
    refundTickets(ticketIds) {

      const uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})

      Swal.fire({
        title: '<span class="text-warning">ยืนยันคืนโพย!</span>',
        html: `<h4>คืน ${ticketIds.length} โพย</h4><br /><span>*** หมายเหตุ ระบบจะกรองเอาบัญชีล่ะ 1 โพย/รอบ ***</span>`,
        icon: 'warning',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#dc3545',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn-warning'
        }
      })
      .then((res)=>{
        if(res.isConfirmed) {
          this.isLoading = true
          LottoService.refundBulkTicket({roundId: this.roundId, ticketIds: ticketIds}, uniqueCode).then((response)=>{
            if(response.success) {
              this.$notify({
                type: 'success',
                title: 'ยกเลิกโพยแล้ว!'
              })
              this.loadTickets()
            }else{
              throw new Error(response?.message)
            }
          })
          .catch((e)=>{
            Swal.fire({
              title: 'ผิดพลาด!',
              text: (e?.message || e?.data) || 'คืนโพยไม่สำเร็จ',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
          .finally(()=>{
            this.sItemIds = []
            this.isLoading = false
          })
        }
      })
    },
    clearSelects() {
      this.sItemIds = []
    },
    selectAll() {
      this.sItemIds = this.items.map(i=>i._id)
    },
    summary(item, key) {
      return item?.summary?.[key] || '0'
    }
  },
  mounted() {
    this.loadTickets()
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-weight: 500;
        line-height: 1;
        font-size: 85%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        padding: 5px;
        vertical-align: middle;
        font-size: 85%;
      }
      th {
        padding: 5px;
        font-size: 85%;
      }
    }
  }
  tfoot {
    tr {
      td {
        font-size: 85%;
      }
    }
  }
}
</style>
